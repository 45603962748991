<!--*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===
  - Project: HR APPLICATION
  - Author: Salwa Abuwarda*
  - Author URL: http://linkedin.com/in/salwa-abuwarda-20b06a142
  ===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===* -->

<template>
  <div id="data-list">
    <vs-popup
      classContent="popup-example"
      title="Confirm Update"
      :active.sync="popupActive"
    >
      <validation-observer ref="observer">
        <form>
          <ValidationProvider
            name="news_password"
            rules="required|min:8"
            v-slot="{ errors }"
          >
            <vs-input
              :danger="!!errors[0]"
              :danger-text="errors[0]"
              type="password"
              name="news_password"
              icon-no-border
              icon="icon icon-lock"
              icon-pack="feather"
              label-placeholder="Password"
              v-model="news_password"
              class="w-full mt-6"
            />
          </ValidationProvider>
          <vs-button type="filled" @click="addNews" class="mb-2 mt-5"
            >Confirm</vs-button
          >
        </form>
      </validation-observer>
    </vs-popup>
    <vx-card
      ref="filterCard"
      title="Update News"
      class="user-list-filters mb-8 vs-con-loading__container"
      id="div-loading"
      collapse-action
      refresh-content-action
      @refresh="resetColFilters"
    >
      <validation-observer ref="observer">
        <form>
          <div class="vx-row">
            <div class="vx-col w-full mb-2">
              <div
                v-for="(category, index) of company_categories"
                :key="index"
                class="vx-row"
              >
                <div class="vx-col md:w-2/5 sm:w-2/5 w-full mb-2">
                  <label class="text-sm opacity-75">
                    <span class="text-primary">*</span>COMPANY
                  </label>
                  <validation-provider
                    v-slot="{ errors }"
                    :vid="'vp' + category.id"
                    name="company_categories"
                    rules="required|min:1"
                  >
                    <v-select
                      label="name"
                      name="company_categories"
                      :reduce="(name) => name.id"
                      :options="companies"
                      :clearable="false"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      v-model="category.company_id"
                      :required="!!errors[0]"
                      class="mb-4"
                      placeholder="Company"
                    />
                  </validation-provider>
                </div>
                <div class="vx-col md:w-2/5 sm:w-2/5 w-full mb-2">
                  <label class="text-sm opacity-75">
                    <span class="text-primary">*</span>CATEGORY
                  </label>
                  <validation-provider
                    v-slot="{ errors }"
                    :vid="'vp' + index"
                    :name="'categories' + index"
                    rules="required|min:1"
                  >
                    <v-select
                      label="name"
                      :reduce="(name) => name.id"
                      multiple
                      :name="'categories' + index"
                      :options="categories"
                      :clearable="false"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      v-model="category.categories"
                      :required="!!errors[0]"
                      class="mb-4 md:mb-0 vs_error"
                      placeholder="Category"
                    />
                  </validation-provider>
                </div>
                <div
                  class="vx-col md:w-1/5 sm:w-1/5 w-full m-auto"
                  style="
                    display: flex;
                    align-items: flex-end;
                    justify-content: flex-end;
                  "
                >
                  <vs-button
                    style="floate: inline-start !important"
                    @click="addCompany()"
                    radius
                    color="success"
                    class="mr-2"
                    type="border"
                    icon="add"
                  ></vs-button>
                  <vs-button
                    style="floate: inline-start !important"
                    v-if="index != 0"
                    @click="removeCompany(index)"
                    radius
                    color="danger"
                    type="border"
                    icon="delete"
                  ></vs-button>
                </div>
              </div>
            </div>

            <div class="vx-col md:w-1/2 sm:w-1/2 w-full mb-2">
              <div class="vx-row">
                <div class="vx-col md:w-full sm:w-full w-full mb-2">
                  <label class="text-sm opacity-75">
                    <span class="text-primary">*</span>Subject
                  </label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="subject"
                    rules="required"
                  >
                    <vs-input
                      class="w-full"
                      icon-pack="feather"
                      icon="icon-chevron-right"
                      icon-no-border
                      v-model="subject"
                      :danger="!!errors[0]"
                      :danger-text="errors[0]"
                    />
                  </validation-provider>
                </div>
                <div class="vx-col md:w-full sm:w-full w-full mb-2">
                  <label class="text-sm opacity-75">
                    <span class="text-primary">*</span>News
                  </label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="body"
                    rules="required|min:15"
                  >
                    <vs-textarea
                      class="w-full"
                      v-model="body"
                      :counter="errors[0]"
                      :counter-danger="!!errors[0]"
                      height="150px"
                    />
                  </validation-provider>
                </div>
              </div>
            </div>
            <div class="vx-col md:w-1/2 sm:w-1/2 w-full mb-2">
              <label class="text-sm opacity-75">
                <span class="text-primary">*</span>Upload Image
              </label>
              <div class="vx-row" style="flex-wrap: inherit">
                <div v-if="!isUserUpdateFile" class="con-img-upload">
                  <vs-images>
                    <vs-image :src="news_image" />
                  </vs-images>
                </div>
                <vs-upload ref="getFile" @change="getFile" limit="1" />
              </div>
            </div>
          </div>

          <vs-button type="filled" @click="openPopUp()" class="mb-2"
            >Send</vs-button
          >
        </form>
      </validation-observer>
    </vx-card>
  </div>
</template>

<script>
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import vSelect from "vue-select";
import endpoints from "@/endpoints";

import axios from "@/axios.js";
// Store Module
// import moduleNewsManagement from "@/store/get-all-news/moduleNewsManagement.js";
import { required, min, numeric } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

setInteractionMode("eager");
// betterEager
extend("required", {
  ...required,
  message: "field can not be empty",
});

extend("min", {
  ...min,
  message: "field may not be less than {length} characters",
});
extend("numeric", {
  ...numeric,
  message: "field should be a number",
});

export default {
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
    // Cell Renderer
  },
  data() {
    return {
      subject: null,
      body: null,
      show: false,
      category_name: null,
      category_id: null,
      company_name: null,
      company_id: null,
      isUserUpdateFile: false,
      fileRequired: false,
      popupActive: false,
      news_password: null,
      news_image: null,
      companies: [],
      categories: [],
      company_categories: [{ categories: [], company_id: "" }],
    };
  },
  computed: {
    itemsData() {
      return this.$store.state.NewsManagement.items;
    },
  },
  methods: {
    openPopUp() {
      this.popupActive = true;
    },
    getFile() {
      this.news_image = this.$refs.getFile.filesx[0];
      this.isUserUpdateFile = true;
    },
    resetColFilters(card) {
      this.getById();
      card.removeRefreshAnimation(500);
    },
    addCompany() {
      this.company_categories.push({
        campany_id: "",
        categories: "",
      });
    },
    removeCompany(i) {
      this.company_categories.splice(i, 1);
    },
    addNews() {
      this.popupActive = false;
      if (!this.news_image) {
        this.$vs.notify({ color: "danger", title: "File is Required" });
      } else {
        this.$vs.loading({
          container: "#div-loading",
          scale: 0.6,
        });
        const formData = new FormData();
        formData.append("title", this.subject);
        formData.append("description", this.body);
        if (this.isUserUpdateFile) {
          formData.append("image", this.news_image);
        }
        this.company_categories.forEach((element, i) => {
          element.categories.forEach((item, index) => {
            formData.append(
              "company_categories[" + i + "][categories][" + index + "]",
              item
            );
          });

          formData.append(
            "company_categories[" + i + "][company_id]",
            element.company_id
          );
        });

        formData.append("_method", "put");
        formData.append("password", this.news_password);
        axios
          .post(
            `${endpoints.NEWS_ENDPOINT}/${this.$route.query.SID}`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            this.$vs.loading.close("#div-loading > .con-vs-loading");

            if (response.status === 200) {
              this.$vs.notify({
                title: "Success",
                text: "News is Added",
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "success",
              });
              this.$router.push("../GetAll/News");
            } else {
              this.$vs.notify({
                title: "Error",
                text: "Message is not sent",
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "danger",
              });
            }
          })
          .catch((error) => {
            this.$vs.loading.close("#div-loading > .con-vs-loading");

            this.$vs.notify({
              title: "Error",
              text: error.message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          });
      }
    },
    getCategories() {
      axios
        .get(`${process.env.VUE_APP_BASE_URI}/api/admin/categories`)
        .then((response) => {
          this.categories = response.data.response.data;
        });
    },
    getCompanies() {
      axios
        .get(`${process.env.VUE_APP_BASE_URI}/api/admin/companies`)
        .then((response) => {
          this.companies = response.data.response.data;
        });
    },
    getById() {
      this.$vs.loading({
        container: "#div-loading",
        scale: 0.6,
      });
      axios
        .get(`${endpoints.NEWS_ENDPOINT}/${this.$route.query.SID}`)
        .then((res) => {
          this.$vs.loading.close("#div-loading > .con-vs-loading");

          const x = res.data.response.data;
          this.subject = x.title;
          this.body = x.description;
          this.news_image = x.image;
          this.company_categories = x.company_categories;
          if (!x.company_categories.length) {
            this.company_categories.push({
              company_id: "",
              categories: "",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close("#div-loading > .con-vs-loading");

          this.$vs.notify({
            title: "Error",
            text: error.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        });
    },
  },
  mounted() {
    this.getCategories();
    this.getCompanies();
    this.getById();
  },
  created() {},
};
</script>

<style lang="scss">
.vs-image .con-vs-image {
  width: 200px;
  height: 200px;
  min-height: 200px;
  overflow: auto;
}
.vs-images-hover-default .vs-image:hover .vs-image--img {
  transform: unset;
}
#data-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
#file {
  padding: 6px !important;
}
.con-input-upload {
  padding: 0;
}
[dir] .con-img-upload {
  padding: 0;
  margin: 0;
}
[dir="rtl"] .ag-theme-material .ag-rtl {
  text-align: right;
}
[dir="rtl"] .ag-rtl {
  direction: rtl;
}
.btn-upload-file {
  display: none !important;
}
</style>
